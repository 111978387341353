<template>
    <div :aria-label="'Tile holding button for ' + buttonText" class="btn-main draw-border">
        <div class="btn-content">
            <div class="img-wrap">
                <font-awesome-icon class="svg" v-if="buttonFa" :icon="buttonFa" />
                <MabdLogo v-else-if="svgPath == 'mabdLogo'" />
                <TimsLogo v-else-if="svgPath == 'timsLogo'" />
                <VdhLogo v-else-if="svgPath == 'vdhLogo'" />
                <OktaLogo v-else-if="svgPath == 'oktaLogo'" />
                <DllEdu class="svg-full" v-else-if="svgPath == 'dllEduLogo'" />
                <DcfGaapsLogo class="svg-full" v-else-if="svgPath == 'DcfGaapsLogo'" />

                <CddProviderLogo class="svg-full" v-else-if="svgPath == 'CddProviderLogo'" />
                <VdolClaim class="svg-full" v-else-if="svgPath == 'vdolClaimLogo'" />
                <img v-else-if="buttonImg != ''" :src="imgLink" alt="" class="png-icon">
            </div>
            <div class="btn-text-wrap">
                <span class="btn-title"> {{ buttonText }}</span>
                <span class="app-button-desc-text">
                    <p>{{ buttonDesc }}</p>
                </span>
                <div>

                    <button :aria-label="'Button to go to app: ' + buttonText" v-if="!buttonPath"
                        @click="goToLink(buttonLink)" type="button" class="btn btn-success" target="blank">
                        <a>{{ buttonCall }}</a>
                    </button>
                    <router-link v-else class="btn btn-success" :to="'/' + buttonPath"><a>{{ buttonCall }}</a></router-link>
                </div>
            </div>
        </div>
        <div :title="'Link to login help page for ' + entity" class="overlay-ribbon-container"
            :aria-label="'Banner containing link to login help page for ' + entity">
            <div class="overlay-ribbon">
                <div class="ribbon-content">
                    <router-link class="agency-label" :to="btnHelp">
                        <font-awesome-icon icon="info-circle" />
                        <div>Login Help @ {{ entity }}</div>
                    </router-link>
                </div>
            </div>
            <div title="ID icon indicating id proofing is required to use the site" class="id-logo">
                <idVermontLogo aria-label="ID icon indicating id proofing is required to use the site" v-if="idProof"
                    class="svg-full id-logo-svg" />
            </div>
        </div>
    </div>
</template>

<script>
import MabdLogo from '../../assets/MabdLogo.vue'
import TimsLogo from '../../assets/TimsLogo.vue'
import VdhLogo from '../../assets/VdhLogo.vue'
import CddProviderLogo from '../../assets/CddProviderLogo'
import DllEdu from '../../assets/DllEdu.vue'
import VdolClaim from '../../assets/vdolWeeklyLogo.vue'
import idVermontLogo from '../../assets/idVermontLogo.vue'
import OktaLogo from '../../assets/OktaLogo'
import DcfGaapsLogo from '../../assets/DcfGaapsLogo'


export default {
    name: 'appButton',
    props: ['appText', 'appLink', 'imgPath', 'fontawesomePath', 'svgPath', 'routerLink', 'appDesc', 'btnCall', 'iconWidth', 'agencyDept', 'helpLink', 'idProof'],
    components: {
        MabdLogo,
        TimsLogo,
        OktaLogo,
        VdhLogo,
        DllEdu,
        CddProviderLogo,
        VdolClaim,
        idVermontLogo,
        DcfGaapsLogo
    },
    data() {
        return {
            buttonText: this.appText,
            buttonLink: this.appLink,
            buttonImg: this.imgPath,
            buttonFa: this.fontawesomePath,
            buttonPath: this.routerLink,
            buttonDesc: this.appDesc,
            buttonCall: this.btnCall,
            mabd: this.mabdLogo,
            dllEdu: this.dllEduLogo,
            entity: this.agencyDept,
            btnHelp: '/' + this.helpLink,
        }
    },
    computed: {
        imgLink: function () {
            // this.imagelink
            return require(`../../assets/${this.buttonImg}`) //changed this
        },
    },
    methods: {
        goToLink: function (link) {
            window.location.assign(link)
        },
    },
}
</script>

<style scoped>
.draw-border {
    box-shadow: inset 0 0 0 2px #007834;
    color: #003300;
    transition: color 0.25s 0.0833333333s;
    position: relative;
    font-family: 'Public Sans', sans-serif;
}

.draw-border::before,
.draw-border::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0;
    height: 0;
    bottom: 0;
    right: 0;
}

.draw-border::before {
    border-bottom-width: 4px;
    border-left-width: 4px;
}

.draw-border::after {
    border-top-width: 4px;
    border-right-width: 4px;
}

.draw-border:hover {
    color: #0a8927;
    text-decoration: none !important;
}

.draw-border:hover::before,
.draw-border:hover::after {
    border-color: #0a8927;
    transition: border-color 0s, width 0.25s, height 0.25s;
    width: 100%;
    height: 100%;
}

.draw-border:hover::before {
    transition-delay: 0s, 0s, 0.25s;
}

.draw-border:hover::after {
    transition-delay: 0s, 0.25s, 0s;
}

.btn-main {
    background-color: white;
    border: none;
    font: 700 'Public Sans', 'Roboto Slab', sans-serif;
    line-height: 1.5;
    padding: 1em 0.4em 0.3em 0.4em;
    letter-spacing: 0.05rem;
    align-items: center;
    display: inline-block;
}

.btn {
    max-width: 150px;
    color: white;
}

a {
    color: inherit;
}

.btn-text-wrap {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 10px;
}

.btn-title {
    font-weight: 800;
}

.app-button-desc-text {
    font-weight: 400;
    font-family: 'Public Sans', 'Roboto Slab', sans-serif;
    margin: 5px 0 5px 0;
}

a,
a:hover {
    text-decoration: none !important;
}

.btn:focus {
    outline: 2px dotted #0a8927;
}

.btn-content {
    display: flex;
    flex-direction: row;
    /* width: auto; */
    align-items: center;
    min-height: 120px;
}

.btn-success {
    background-color: #0a8927;
}

.img-wrap {
    width: 40%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.img-wrap img {
    /* max-height: 50%; */
    width: 100%;
}

.img-wrap .svg {
    color: inherit;
    font-size: 50px;
}

.btn {
    font-size: inherit !important;
}

.overlay-ribbon-container {
    min-height: 2.3em;
    margin-top: 10px;
}

.overlay-ribbon {
    position: relative;
    bottom: 1%;
    left: 0%;
    width: 60%;
    z-index: 99;
    margin-bottom: 5px;
    margin-left: -.3em;
    background: #457a7c;
    padding: 0 0 0 10px;
}

.ribbon-content {
    z-index: 100;
    position: relative;
    margin: 1px;
    text-decoration: underline;
    font-weight: 800;
}

.ribbon-content svg {
    font-size: 1.9em;
    padding-right: 5px;
}

.agency-label:hover {
    color: #3db5e6;
}

.agency-label {
    display: flex;
    flex-direction: row;
    font-size: 0.7em;
    margin: 1em -0.4em 1em -0.4em;
    width: 100%;
    text-align: left;
    padding: 0 1px 0 1px;
    color: white;
}

.agency-label div {
    margin-top: 4px;
    margin-left: 3px;
}

.id-logo {
    position: relative;
    float: right;
    right: 4%;
    max-width: 20%;
    top: 65%;
    background: white;
    margin-top: -10%;
}

.id-logo-svg {
    width: 100%;
}</style>
