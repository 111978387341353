<template>
    <div class="info-help">
        <div class="infobody-help">
            <div class="white-header">
                <div class="tenforty-wrap">
                    <router-link to="/" class="c-back__box">
                        <span class="c-back__icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="14px">
                                <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path>
                            </svg>
                        </span>
                        <div>Return to info.my.vermont.gov</div>
                    </router-link>
                    <h1>Welcome to CDDIS Support!</h1>
                    <div class="sub-headings">
                        <p><b>How can we help you?</b></p>
                        <div class="image-row">
                            <font-awesome-icon class="help-icon" icon="child" />
                            <div class="img-wrap">
                                <img class="svg-file" src="../../assets/CDD-logo.png" />
                            </div>
                        </div>
                        <p class="heading-instruction">
                            Please check the common problems and solutions for CDDIS access before contacting the customer support.
                        </p>
                    </div>
                </div>
            </div>
            <div class="tenforty-wrap">
                <h2 class="faq-header">
                    How to Register for the CDDIS Family or Provider Portal
                </h2>
                <p class="faq-text">
                The Child Development Division has to specifically authorize both families and child care providers to access the CDDIS family and provider portals. If you are getting an error when logging in with your myVermont account, then you may not yet be authorized. Simply go to<a class="email-link" href="https://cddishelpdesk.vermont.gov"> the CDDIS help desk </a> and submit a request for access. The request should be addressed within 1 to 5 business days depending on the volume of requests.
             </p>
                             <h2 class="faq-header">
                    How to access the CDDIS Family or Provider Portal
                </h2>
                <p class="faq-text">
                The CDDIS family portal can be accessed by visiting <a class="email-link" href="https://childcare.vermont.gov"> childcare.vermont.gov</a>. The provider portal can be accessed by visiting <a class="email-link" href="https://childcareproviders.vermont.gov"> childcareproviders.vermont.gov</a>.
             </p>
                <HelpGeneral :envLinks="envLinks"></HelpGeneral>
                <div class="noteAlt">
                    <i><font-awesome-icon icon="info-circle" /></i>
                    <div class="noteText">
                        <p>
                            If you do not find an answer here, please submit a request for assistance at <a class="email-link" href="https://cddishelpdesk.vermont.gov">the CDDIS help desk</a>.
                        </p>
                        <p>
                            Please do not include any personal or identifying information (e.g. Date of Birth or SSN) in your form submission. If necessary, you will be contacted by a State of Vermont CDD support representative to assist you via phone.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HelpGeneral from './HelpGeneral'

export default {
    name: 'help-benefits',
    props: ['envLinks'],
    components: { HelpGeneral },
}
</script>

<style scoped>
@import './help.css';
</style>
