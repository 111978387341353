<template>
    <div>
        <h2 class="faq-header">Forgot your password</h2>

        <p class="faq-text">Click the "<a :href="envLinks.oktaDomain + '/signin/forgot-password'" class="forgot-link">Forgot password?</a>" link on the login screen, enter your email address and click submit. An email will be sent to you with a link to reset your password. Click on the link in the email, which will open the password reset screen where you can enter the new password twice and submit.</p>

        <h2 class="faq-header">Forgot your login email address</h2>

        <p class="faq-text">Click the "<a :href="envLinks.oktaDomain + '/signin/forgot-password'" class="forgot-link">Forgot password?</a>" link in the login screen, enter your email address and submit. If the email address you entered is your login email address, then you will receive an email with a link to reset your password. If you did not receive the reset password email within ten (10) minutes and you do have more than one email accounts, then try the “<a class="forgot-link" :href="envLinks.oktaDomain + '/signin/forgot-password'">Forgot password?</a>” link with your other email accounts. Otherwise, <a :href="envLinks.oktaDomain + '/signin/register'" class="register-link">create a new account</a>.</p>

        <h2 class="faq-header">Your account got locked</h2>

        <p class="faq-text">The account gets locked if you try to log in with an incorrect password three consecutive times; this is to protect your account. You will receive an email notification if your account is locked. The locked account will be automatically unlocked after thirty (30) minutes. You can either wait thirty (30) minutes and try to log in again or click the “<a :href="envLinks.oktaDomain + '/signin/unlock'" class="unlock-link">Unlock account?</a>” link in the login screen and enter your email address. You will be sent an email with a link to unlock your account. Click on the link in the email to unlock your account, and you should be able to log in.</p>

        <h2 class="faq-header">Your password expired</h2>

        <p class="faq-text">You are required to change your password every 180 days. Password expiration is for your security. We send reminder emails to users when their password is about to expire. If your password has expired, log in with your expired password, and you will be asked to set a new password. If you do not remember your expired password, click the “<a class="forgot-link" :href="envLinks.oktaDomain + '/signin/forgot-password'">Forgot password?</a>” link on the login screen and reset your password.</p>

        <h2 class="faq-header">Your account was suspended</h2>

        <p class="faq-text">
            Account suspension is for your security. We are required to suspend accounts if they have not been used for fifteen (15) months. We send reminder emails to users when their account is about to be suspended. If you were unable to prevent the account suspension and want to activate your account, please reach out to the appropriate customer support contact as provided at the bottom of this page.
        </p>
    </div>
</template>

<script>
export default {
    name: 'helpGeneral',
    props: ['envLinks'],
}
</script>

<style scoped>
@import './help.css';
</style>
